<div class="flight-search-results__container">
  <div class="flex-row-container flight-search-results__ticket-container" *ngIf="flight">
    <div
      class="flex-column-container flight-search-results__ticket-details"
      *ngIf="!showLegs"
      [ngClass]="{ 'show-fares-block': showFaresBlock }">
      <app-flight-header
        class="flight-header-border"
        [flightJourney]="flight.outboundFlights[0]"
        [lastFlightJourney]="lastOutboundFlightJourney"
        [allJourneys]="flight.outboundFlights"
        [source]="flight.source"
        [duration]="flight.outboundTotalDuration"
        (displayLegs)="showLegs = $event"
        [preferred]="flight?.preferred"
        [preferenceLevel]="flight?.preferenceLevel"
        [preferredMessages]="flight?.preferredMessages"
        [tmcPreferredLogoUrl]="flight?.tmcPreferredLogoUrl"
        [searchType]="searchService.searches[ServiceType.Flight]?.chosenSearchType">
      </app-flight-header>
      <app-flight-header
        *ngIf="searchService.searches[ServiceType.Flight].isReturn && !isDualFlight"
        [flightJourney]="flight.inboundFlights[0]"
        [lastFlightJourney]="lastInboundFlightJourney"
        [allJourneys]="flight.inboundFlights"
        [source]="flight.source"
        [isReturn]="true"
        [duration]="flight.inboundTotalDuration"
        [searchType]="searchService.searches[ServiceType.Flight]?.chosenSearchType">
      </app-flight-header>
    </div>
    <div
      class="flex-column-container fare-container"
      [ngStyle]="{ minWidth: searchParams.bySchedule ? '10%' : '30%' }">
      <ng-container *ngFor="let combinedFlight of combinedFlight.flights; let j = index">
        <ng-container *ngIf="!searchParams.bySchedule; else multiCityByScheduleBlock">
          <div
            id="fare-wrapper-{{ flightIndex }}-{{ j }}"
            class="flex-row-container fare-wrapper"
            *ngIf="j <= 2">
            <div class="flight-fares">
              <span class="cabin-class">
                {{ combinedFlight.outboundFlights[0].cabinClass | splitCamelCase
                }}<span *ngIf="showAltClass(combinedFlight)"
                  >/<br />{{ combinedFlight.inboundFlights[0].cabinClass | splitCamelCase }}</span
                >
              </span>
              <app-mixed-cabin-tooltip
                *ngIf="
                  showMixedCabin(combinedFlight.outboundFlights) ||
                  showMixedCabin(combinedFlight.inboundFlights)
                "
                [flights]="combinedFlight.outboundFlights"
                [inboundFlights]="combinedFlight?.inboundFlights"></app-mixed-cabin-tooltip>
            </div>
            <div
              tabindex="0"
              class="special-fares-container"
              *ngIf="showSpecialFares(combinedFlight)">
              <app-special-fare-icon [flight]="combinedFlight"></app-special-fare-icon>
            </div>
            <div
              class="flex-row-container flight-search-results__ticket-button-icons price-wrapper"
              [ngStyle]="{
                'padding-right.px':
                  !combinedFlight?.requiresReasonKeys?.length && !combinedFlight?.unavailable
                    ? 30
                    : 0
              }">
              <div class="flex-row-container">
                <!-- <fa-icon OLD CODE
              *ngIf="flight.source.includes('TFX')"
              ngbTooltip="The fare selected cannot be cancelled or amended online. Amendments to the fare you are selecting may be possible and fees may apply (check each fare rule). Should you need to make a change once your booking is confirmed, please contact your Travel Management Company Online Support team for help."
              class="ba-tfx-hand"
              tooltipClass="tfxTooltip"
              placement="bottom"
              [icon]="['fas', 'hand-paper']">
            </fa-icon> -->
                <span class="flight-search-results__ticket-button-price" id="price">
                  {{ combinedFlight.total.price | currency : flight.total.currency }}
                </span>
              </div>
              <div
                class="flight-baggage"
                *ngIf="!isExchange || isPricingExchange"
                id="flight-baggage-{{ flightIndex }}-{{ j }}">
                <!-- Use ng-container to declare variables -->
                <ng-container
                  [ngTemplateOutlet]="baggageTemplate"
                  [ngTemplateOutletContext]="{
                    outboundBaggage: searchParams.getTotalBaggage(combinedFlight),
                    inboundBaggage: searchParams.getTotalBaggage(combinedFlight, false)
                  }">
                </ng-container>

                <!-- Template to display baggage information -->
                <ng-template
                  #baggageTemplate
                  let-outboundBaggage="outboundBaggage"
                  let-inboundBaggage="inboundBaggage">
                  <b>
                    <!-- Display outbound baggage -->
                    <span>
                      {{ outboundBaggage }}
                      <span *ngIf="outboundBaggage !== 1; else oneBag">
                        <span i18n="@@bags">bags</span>
                      </span>
                      <ng-template #oneBag>
                        <span i18n="@@bag">bag</span>
                      </ng-template>
                    </span>

                    <!-- Display inbound baggage if different -->
                    <span
                      *ngIf="
                        outboundBaggage !== inboundBaggage && searchParams.isReturn && !isDualFlight
                      ">
                      / {{ inboundBaggage }}
                      <span *ngIf="inboundBaggage !== 1; else baseOneBag">
                        <span i18n="@@bags">bags</span>
                      </span>
                      <ng-template #baseOneBag>
                        <span i18n="@@bag">bag</span>
                      </ng-template>
                    </span>
                  </b>
                </ng-template>
              </div>
            </div>
            <div class="flex-column-container">
              <div class="flex-row-container book-wrapper">
                <div
                  *ngIf="combinedFlight?.requiresReasonKeys?.length || combinedFlight?.unavailable">
                  <app-warning-tags
                    iconId="{{ flightIndex }}-{{ j }}"
                    [ticket]="getFareRules(combinedFlight)"
                    [displayCheck]="false"></app-warning-tags>
                </div>
                <!-- [disabled]="getFareRules(combinedFlight)?.unavailable" -->
                <button
                  *ngIf="!isPricingExchange"
                  [ngClass]="{
                    'selected-button':
                      searchParams.selectedFlight.value &&
                      isDualFlight &&
                      !searchParams.fetchingRules
                  }"
                  [disabled]="
                    (combinedFlight?.unavailable && !canOverride) ||
                    (!searchParams.selectedFlight.value && isDualReturn)
                  "
                  (click)="checkDualClassAndSelect(combinedFlight)"
                  [attr.aria-label]="
                    searchParams.selectedFlight.value && isDualFlight && !searchParams.fetchingRules
                      ? 'Deselect '
                      : 'Select ' + getTicketSelectAriaLabel(combinedFlight)
                  "
                  class="small-button select-button"
                  id="flight-display-button-select-{{ flightIndex }}-{{ j }}">
                  <span>{{
                    (searchParams.selectedFlight.value &&
                    isDualFlight &&
                    !searchParams.fetchingRules
                      ? 'Deselect'
                      : 'Select'
                    ) | translate
                  }}</span>
                  <i
                    *ngIf="
                      searchParams.fetchingRules &&
                      combinedFlight.journeyHash === searchParams.selectedUId
                    "
                    class="button_spinner"></i>
                </button>
                <button
                  class="small-button select-button spanish_button"
                  [ngClass]="{
                    'selected-button': combinedFlight.total.discount
                  }"
                  *ngIf="checkIfSpanishResidencyApplies(combinedFlight)"
                  [disabled]="combinedFlight.total.discount"
                  i18n="@@ResidentDiscount"
                  (click)="searchParams.repriceFlight(combinedFlight)">
                  Resident Discount
                </button>
              </div>
              <div
                class="flight_leg_co2"
                *ngIf="combinedFlight.co2PerItem"
                id="co2-{{ flightIndex }}-{{ j }}">
                {{ combinedFlight.co2PerItem }} <span i18n="@flight_kg">kg</span>&nbsp;<span
                  i18n="@flight_leg_co2"
                  >CO</span
                ><sub i18n="@flight_leg_2">2</sub>&nbsp;<span i18n="@flight_pp">pp</span>
              </div>
              <!-- [disabled]="getFareRules(combinedFlight)?.unavailable" -->
            </div>
          </div>
        </ng-container>
        <ng-template #multiCityByScheduleBlock>
          <div class="flex-row-container" *ngIf="j <= 0">
            <div class="flex-column-container fare-wrapper" [ngStyle]="{ alignItems: 'center' }">
              <div class="flex-row-container book-wrapper">
                <div *ngIf="combinedFlight?.requiresReason || combinedFlight?.unavailable">
                  <app-warning-tags
                    iconId="{{ flightIndex }}-{{ j }}"
                    [ticket]="getFareRules(combinedFlight)"
                    [displayCheck]="false"></app-warning-tags>
                </div>
                <button
                  [disabled]="(disableItems && !deselectItem) || combinedFlight?.unavailable"
                  (click)="selectedMultiCityByScheduleFlight(combinedFlight)"
                  [attr.aria-label]="
                    deselectItem
                      ? 'Deselect '
                      : 'Select ' + getTicketSelectAriaLabel(combinedFlight)
                  "
                  class="small-button select-button"
                  id="flight-display-button-select-{{ flightIndex }}-{{ j }}">
                  <span>{{ (deselectItem ? 'Deselect' : 'Select') | translate }}</span>
                </button>
              </div>
              <div class="flight_leg_co2" *ngIf="combinedFlight.co2PerItem">
                {{ combinedFlight.co2PerItem }} <span i18n="@flight_kg">kg</span>&nbsp;<span
                  i18n="@flight_leg_co2"
                  >CO</span
                ><sub i18n="@flight_leg_2">2</sub>&nbsp;<span i18n="@flight_pp">pp</span>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <!-- <span style="color: black">HERE {{flight.uid}}</span> -->
  <section (mouseleave)="tt.close()" class="container" *ngIf="flight?.greenerChoice">
    <div
      class="flightDetails_greenerChoice"
      placement="top"
      triggers="manual"
      [ngbTooltip]="greenChoiceTooltip"
      #tt="ngbTooltip"
      (mouseenter)="tt.open()"
      (focus)="tt.open()"
      (blur)="tt.close()"
      tabindex="0"
      attr.aria-label="{{
        themeService.isLightningEnv ? 'CTM ' : ''
      }}Greener Choice - This flight emits {{
        flight?.greenerChoicePercentage | greenerPercentage
      }}% less CO2 than the average for your search">
      <img
        class="flightDetails_greenerChoiceLogo"
        src="https://images.sabscorp.com/images/logo/seedling.svg"
        alt="Greener Choice" />
      <div class="flightDetails_greenerChoiceText">
        <span *ngIf="themeService.isLightningEnv" i18n="@@flightDetails_greenerChoiseHeader"
          >CTM Greener Choice</span
        >
        <span *ngIf="themeService.isScionEnv" i18n="@@flightDetails_greenerChoiseHeader2"
          >Greener Choice</span
        >
      </div>
      <span class="sr-only"
        >This flight emits {{ flight?.greenerChoicePercentage | greenerPercentage }}% less CO2 than
        the average for your search</span
      >
    </div>
  </section>
  <div class="flight_detail_container" *ngIf="!gmt">
    <div class="flight_detail_header">
      <div class="flight-saf" ngbTooltip="Sustainable Aviation Fuel Score">
        <img
          alt=""
          class="flightDetails_safLogo"
          src="https://images.sabscorp.com/images/enterprise/SAF-Icon.svg" />
        <b class="saf-text">SAF: {{ flight.safRating }}</b>
      </div>
      <div
        class="flight_detail_info"
        (click)="showDetail = !showDetail"
        role="button"
        [attr.aria-expanded]="showDetail"
        attr.aria-controls="flight-detail-expended"
        tabindex="0"
        (keydown.enter)="showDetail = !showDetail"
        id="flight-display-show-details-{{ flightIndex }}"
        [attr.aria-label]="'Show trip details and fares for ' + getTicketExpandAriaLabel()">
        <b class="detail-accordion">{{
          (showDetail ? 'Collapse' : 'Show trip details & fares') | translate
        }}</b>
      </div>
    </div>
    <div class="flight_detail_showdetail" id="flight-detail-expended-3" *ngIf="showDetail">
      <app-flight-journey-detail
        (selectedMultiCityFlight)="selectedMultiCityFlight.emit($event)"
        (deselectMultiCityFlight)="deselectMultiCityFlight.emit($event)"
        [selectedItemsId]="selectedItemsId"
        [disableItems]="disableItems"
        [isExchange]="isExchange"
        [isPricingExchange]="isPricingExchange"
        [isDualFlight]="isDualFlight"
        [isDualReturn]="isDualReturn"
        (addToBasketEvent)="selectedFlight($event)"
        [flightDetail]="flight"
        [fares]="combinedFlight.flights"
        [search]="search"
        [searchParams]="searchParams"
        [flightIndex]="flightIndex"></app-flight-journey-detail>
    </div>
  </div>
</div>
<ng-template #greenChoiceTooltip>
  <span i18n="@@flightDetails_greenerChoiseText">
    This flight emits
    <em>{{ flight?.greenerChoicePercentage | greenerPercentage }}% less CO<sub>2</sub> </em>
    than the average for your search
  </span>
</ng-template>
