<div class="more-fares-wrapper" *ngIf="showFaresBlock">
  <table class="fares-table-container" aria-label="Fare details table">
    <thead>
      <tr>
        <ng-container *ngIf="!isExchange || isPricingExchange">
          <th class="class-header"><span *ngIf="isReturnFlight">Outbound</span></th>
          <th class="small-block">Hand Baggage</th>
          <th class="small-block">Hold Baggage</th>
          <!-- We will need more changes here for Sabre, just show this for TFX for now(new ticket ENT-6375)-->
          <th aria-label="Refund" class="small-block" *ngIf="isTravelfusion">Refunds</th>
          <th class="small-block" *ngIf="isTravelfusion">Changes</th>
          <ng-container *ngIf="isReturnFlight">
            <th class="border-left-mixed class-header">Inbound</th>
            <th class="small-block">Hand Baggage</th>
            <th class="small-block">Hold Baggage</th>
          </ng-container>
          <!-- We will need more changes here for Sabre, just show this for TFX for now(new ticket ENT-6375)-->
          <th class="small-block" *ngIf="isTravelfusion">Refunds</th>
          <th class="small-block" *ngIf="isTravelfusion">Changes</th></ng-container
        >

        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let flight of fares; let j = index">
        <td>
          <div class="cabin-class">
            {{ flight.outboundFlights[0].cabinClass | splitCamelCase }}
            <span *ngIf="flight.outboundFlights[0].additional.fareBrand?.name"
              >({{ flight.outboundFlights[0].additional.fareBrand?.name | titlecase }})
              <span class="tooltip_wrap" *ngIf="showSaverAerLingusTravelfusionTooltip(flight)">
                <section (mouseleave)="tt.close()" class="container">
                  <i
                    class="currency_info_icon"
                    placement="top"
                    triggers="manual"
                    #tt="ngbTooltip"
                    (mouseenter)="tt.open()"
                    (focus)="tt.open()"
                    (blur)="tt.close()"
                    i18n-ngbTooltip
                    tabindex="0"
                    container="body"
                    ngbTooltip="The fare inclusive hand baggage option you have chosen doesn't allow you to take the bag onboard. You can check your bag in at the airport free of charge which will then go into the hold or you can pay a small fee to carry your bag with you and place it in the overhead locker."
                    aria-label="The fare inclusive hand baggage option you have chosen doesn't allow you to take the bag onboard. You can check your bag in at the airport free of charge which will then go into the hold or you can pay a small fee to carry your bag with you and place it in the overhead locker.">
                  </i>
                </section>
              </span>
            </span>
            <app-mixed-cabin-tooltip
              *ngIf="showMixedCabin(flight.outboundFlights)"
              [flights]="flight.outboundFlights"></app-mixed-cabin-tooltip>
          </div>
          <div class="seats-available">
            <ng-container *ngIf="flight.outboundFlights[0].seatsAvailable">
              <i class="ico-plain-seat" aria-hidden="true"></i>
              <span>{{ flight.outboundFlights[0].seatsAvailable }}</span>
              <span i18n="@@flight_seatsLeft" class="sr-only">Seats left</span>
            </ng-container>
            <span *ngIf="!flight.outboundFlights[0].seatsAvailable" i18n="@@flight_SeatsUnknown"
              >Number of seats unknown</span
            >
            <ng-container
              *ngIf="
                (flight.outboundFlights[0]?.bookingCode || flight.outboundFlights[0]?.cabinCode) &&
                userIsAgent
              ">
              <span>&nbsp;(</span>
              <span>{{
                flight.outboundFlights[0]?.bookingCode || flight.outboundFlights[0]?.cabinCode
              }}</span>
              <span
                *ngIf="
                  flight.outboundFlights[0]?.bookingCode || flight.outboundFlights[0]?.cabinCode
                ">
                /
                {{
                  flight.outboundFlights[0]?.bookingCode || flight.outboundFlights[0]?.cabinCode
                }}</span
              >
              <span>)</span>
            </ng-container>
          </div>
          <button
            (click)="getRouteHappyInfo(flight, flight.outboundFlights)"
            class="routehappy_button">
            <span class="visuallyhidden">Outbound&nbsp;</span>
            Flight Details
            <span class="visuallyhidden">&nbsp;for {{ visuallyHiddenTexts[j] }} fare</span>
          </button>
        </td>
        <ng-container *ngIf="!isExchange || isPricingExchange">
          <td>
            <app-ancillary-status-icon
              [isReturnFlight]="isReturnFlight"
              [ancillaryStatus]="
                transformedFareAncillaries[j].hangBaggage
              "></app-ancillary-status-icon>
          </td>
          <td>
            <b
              >{{ searchParams.getTotalBaggage(flight) }}
              <span *ngIf="searchParams.getTotalBaggage(flight) !== 1; else oneBag">
                <span i18n="@@bags">bags</span>
              </span>
              <ng-template #oneBag>
                <span i18n="@@bag">bag</span>
              </ng-template>
            </b>
          </td>
        </ng-container>
        <!-- We will need more changes here for Sabre, just show this for TFX for now(new ticket ENT-6375)-->
        <td *ngIf="isTravelfusion">
          <app-ancillary-status-icon
            [isReturnFlight]="isReturnFlight"
            [ancillaryStatus]="transformedFareAncillaries[j].refund"></app-ancillary-status-icon>
        </td>
        <td *ngIf="isTravelfusion">
          <app-ancillary-status-icon
            [isReturnFlight]="isReturnFlight"
            [ancillaryStatus]="transformedFareAncillaries[j].changes"></app-ancillary-status-icon>
        </td>
        <ng-container *ngIf="isReturnFlight">
          <td class="border-left-mixed inbound-details">
            <div class="cabin-class">
              {{ flight.inboundFlights[0].cabinClass | splitCamelCase }}
              <span *ngIf="flight.inboundFlights[0].additional.fareBrand?.name"
                >({{ flight.inboundFlights[0].additional.fareBrand?.name | titlecase }})
                <span class="tooltip_wrap" *ngIf="showSaverAerLingusTravelfusionTooltip(flight)">
                  <section (mouseleave)="tt.close()" class="container">
                    <i
                      class="currency_info_icon"
                      placement="top"
                      triggers="manual"
                      #tt="ngbTooltip"
                      (mouseenter)="tt.open()"
                      (focus)="tt.open()"
                      (blur)="tt.close()"
                      i18n-ngbTooltip
                      tabindex="0"
                      container="body"
                      ngbTooltip="The fare inclusive hand baggage option you have chosen doesn't allow you to take the bag onboard. You can check your bag in at the airport free of charge which will then go into the hold or you can pay a small fee to carry your bag with you and place it in the overhead locker."
                      aria-label="The fare inclusive hand baggage option you have chosen doesn't allow you to take the bag onboard. You can check your bag in at the airport free of charge which will then go into the hold or you can pay a small fee to carry your bag with you and place it in the overhead locker.">
                    </i>
                  </section>
                </span>
              </span>
              <app-mixed-cabin-tooltip
                *ngIf="showMixedCabin(flight.inboundFlights)"
                [flights]="flight.inboundFlights"></app-mixed-cabin-tooltip>
            </div>
            <div class="seats-available">
              <ng-container *ngIf="flight.inboundFlights[0].seatsAvailable">
                <i class="ico-plain-seat" aria-hidden="true"></i>
                <span>{{ flight.inboundFlights[0].seatsAvailable }}</span>
                <span i18n="@@flight_seatsLeft" class="sr-only">Seats left</span>
              </ng-container>
              <span *ngIf="!flight.inboundFlights[0].seatsAvailable" i18n="@@flight_SeatsUnknown"
                >Number of seats unknown</span
              >
              <ng-container
                *ngIf="
                  (flight.inboundFlights[0]?.bookingCode || flight.inboundFlights[0]?.cabinCode) &&
                  userIsAgent
                ">
                <span>&nbsp;(</span>
                <span>{{
                  flight.inboundFlights[0]?.bookingCode || flight.inboundFlights[0]?.cabinCode
                }}</span>
                <span>)</span>
              </ng-container>
            </div>
            <button
              (click)="getRouteHappyInfo(flight, flight.inboundFlights)"
              class="routehappy_button">
              <span class="visuallyhidden">Inbound&nbsp;</span>
              Flight Details
              <span class="visuallyhidden">&nbsp;for {{ visuallyHiddenTexts[j] }} fare</span>
            </button>
          </td>
          <ng-container *ngIf="!isExchange || isPricingExchange">
            <td>
              <app-ancillary-status-icon
                [isReturnFlight]="isReturnFlight"
                [ancillaryStatus]="
                  transformedFareAncillaries[j].hangBaggage
                "></app-ancillary-status-icon>
            </td>
            <td>
              <b
                >{{ searchParams.getTotalBaggage(flight, false) }}
                <span *ngIf="searchParams.getTotalBaggage(flight, false) !== 1; else oneBag">
                  <span i18n="@@bags">bags</span>
                </span>
                <ng-template #oneBag>
                  <span i18n="@@bag">bag</span>
                </ng-template>
              </b>
            </td>
            <!-- We will need more changes here for Sabre, just show this for TFX for now(new ticket ENT-6375)-->
            <td *ngIf="isTravelfusion">
              <app-ancillary-status-icon
                [isReturnFlight]="isReturnFlight"
                [ancillaryStatus]="
                  transformedFareAncillaries[j].refund
                "></app-ancillary-status-icon>
            </td>
            <td *ngIf="isTravelfusion">
              <app-ancillary-status-icon
                [isReturnFlight]="isReturnFlight"
                [ancillaryStatus]="
                  transformedFareAncillaries[j].changes
                "></app-ancillary-status-icon>
            </td>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!isPricingExchange">
          <td>
            <a
              class="fare_rules_link"
              *ngIf="!isAerLingus"
              (click)="getFareRules(flight)"
              (keydown.enter)="getFareRules(flight)"
              tabindex="0"
              role="link">
              <i class="currency_info_icon" aria-hidden="true"></i>
              <span i18n="@@flightTypeTicketsFareRules">Fare Rules</span>
              <span class="visuallyhidden">&nbsp;for {{ visuallyHiddenTexts[j] }} fare</span>
            </a>

            <a
              class="fare_rules_link"
              *ngIf="isAerLingus"
              href="https://www.aerlingus.com/localized/en/modals/fare-rules.html"
              target="_blank"
              tabindex="0"
              role="link">
              <i class="ico-login fare_link_info_icon" aria-hidden="true"></i>
              <span i18n="@@flightTypeTicketsFareRules">Fare Rules</span>
              <span class="visuallyhidden">&nbsp;for {{ visuallyHiddenTexts[j] }} fare</span>
            </a>
            <ng-container *ngIf="isAerLingus">
              &nbsp; &nbsp;
              <a
                class="fare_rules_link"
                href="https://www.aerlingus.com/support/booking-flexibility/cancelling-your-booking/"
                target="_blank"
                tabindex="0"
                role="link">
                <i class="ico-login fare_link_info_icon" aria-hidden="true"></i>
                <span i18n="@@flightCancelRules">Cancellation Rules</span>
              </a>
            </ng-container>

            <div class="flight_leg_co2">
              {{ flight.co2PerItem }} <span i18n="@@flight_kg">kg</span>&nbsp;<span
                i18n="@@flight_leg_co2"
                >CO</span
              ><sub i18n="@@flight_leg_2">2</sub>&nbsp;<span i18n="@@flight_pp">pp</span>
            </div>
          </td>
        </ng-container>
        <td>
          <div class="special-fares-container" *ngIf="showSpecialFares(flight)">
            <app-special-fare-icon [flight]="flight"></app-special-fare-icon>
          </div>
        </td>
        <td class="book-fare-wrapper">
          <div>
            <app-warning-tags
              [iconId]="j"
              [ticket]="fareRulesObject[flight.uid]"
              [displayCheck]="false"></app-warning-tags>
          </div>
          <!-- *ngIf="userIsAgent && userService.bobo && !isTravelfusion" -->
          <div class="reprice-container" *ngIf="userIsAgent && userService.bobo && !isTravelfusion">
            <button class="small-button" aria-label="Reprice" (click)="repriceFlight(flight)">
              Reprice
              <span class="visuallyhidden">&nbsp;{{ visuallyHiddenTexts[j] }}</span>
            </button>
          </div>
          <div class="price-container">
            <div class="flight-search-results__ticket-button-price">
              <span *ngIf="flight.total.price">{{
                flight.total.price | currency : flight.total.currency
              }}</span>
            </div>

            <button
              [ngClass]="{ 'selected-button': searchParams.selectedFlight.value && isDualFlight }"
              [disabled]="
                (fareRulesObject[flight.uid]?.unavailable && !canOverride) ||
                checkFareRules(flight)?.unavailable ||
                (disableItems && !checkAviability(flight.scheduleId))
              "
              (click)="selectFare(flight)"
              class="small-button fare_details_button">
              {{
                ((searchParams.selectedFlight.value && isDualFlight) ||
                searchParams.selectedFlight.value?.journeyHash === flight.journeyHash
                  ? 'Deselect'
                  : 'Select'
                ) | translate
              }}
              <span class="visuallyhidden"
                >{{ flight.total.price | currency : flight.total.currency }} fare</span
              >
            </button>
            <button
              class="small-button fare_details_button spanish_button"
              *ngIf="checkIfSpanishResidencyApplies(flight)"
              [disabled]="flight.total.discount"
              [ngClass]="{ 'selected-button': flight.total.discount }"
              i18n="@@ResidentDiscount"
              (click)="searchParams.repriceFlight(flight)">
              Resident Discount
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
