import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  FlightItinerary,
  HelperRoutines,
  EnterpriseSearchService,
  ServiceType,
  InputFlightJourney,
  ModalOpenerService,
  UserService,
  DataArgs,
  RouteHappyResults,
  FlightJourney,
  WithSubscriptionComponent
} from '@sabstravtech/obtservices/angular';
import {
  FlightEnterpriseSearchInterface,
  FlightItineraryWithExtensions,
  FlightDirectionEnum,
  ServiceProvider
} from '@sabstravtech/obtservices/base';
import { TransformedFareAncillaries } from '../../../../../vendor/interfaces/flight-interfaces';
import { AncillaryStatus } from '../../../../../vendor/enum/ancillary-status.enum';
import { LightningModalTypes } from '../../../../../vendor/classes/modal-types.enum';
import { CurrencyPipe } from '@angular/common';
import moment from 'moment';
import _ from 'lodash';
import { lastValueFrom } from 'rxjs';
import { DialogService } from '../../../../../shared/services/dialog.service';
@Component({
  selector: 'app-fares-table',
  templateUrl: './fares-table.component.html',
  styleUrls: ['./fares-table.component.scss']
})
export class FaresTableComponent extends WithSubscriptionComponent implements OnInit {
  @Input() isMultiCity = false;
  @Input() isDualFlight: boolean;
  @Input() isDualReturn: boolean;
  @Input() showFaresBlock = false;
  @Input() isTravelfusion: boolean = false;
  @Input() fares: FlightItinerary[];
  @Input() searchParams: FlightEnterpriseSearchInterface;
  @Input() disableItems: boolean = false;
  @Input() selectedItemsId: string[] = [];
  @Input() isExchange = false;
  @Input() isPricingExchange = false;
  @Output() addToBasketEvent: EventEmitter<FlightItinerary> = new EventEmitter();
  @Output() selectedMultiCityFlight: EventEmitter<FlightItinerary> = new EventEmitter();
  @Output() deselectMultiCityFlight: EventEmitter<FlightItinerary> = new EventEmitter();
  ancillariesArray = ancillariesArray;
  fareAncillaries: AncillaryStatus[][] = [];
  ancillaryStatus: typeof AncillaryStatus = AncillaryStatus;
  listOfRules: any[] = [];
  transformedFareAncillaries: TransformedFareAncillaries[] = [];
  isAerLingus: boolean = false;
  userIsAgent: boolean = false;
  isReturnFlight: boolean = false;
  fareRulesObject: any = {};
  canOverride: boolean = false;
  visuallyHiddenTexts = [];

  ServiceProvider = ServiceProvider;
  constructor(
    private helpers: HelperRoutines,
    private searchService: EnterpriseSearchService,
    private modalService: ModalOpenerService,
    private currencyPipe: CurrencyPipe,
    public userService: UserService,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit() {

    this.canOverride = this.userService.canOverride();

    if (this.fares[0]?.outboundFlights[0]?.marketingCarrier === 'EI') {
      this.isAerLingus = true;
    }

    this.userIsAgent = this.userService.userIsAgent();

    this.fares.forEach((fare, i) => {
      let ancillaries: AncillaryStatus[] = [];
      ancillariesArray.forEach(a => {
        ancillaries.push(this.getAncillaryType(a.title, fare));
      });

      this.fareAncillaries.push(ancillaries);
      this.transformedFareAncillaries = this.fareAncillaries.map(originalObject => {
        return {
          hangBaggage: originalObject[0],
          holdBaggage: originalObject[1],
          refund: originalObject[2],
          changes: originalObject[3]
        };
      });
    });
    this.isReturnFlight = this.fares.filter(flight => flight.inboundFlights?.length > 0).length > 0;

    for (let flight of this.fares) {
      this.fareRulesObject[flight.uid] = this.checkFareRules(flight as FlightItineraryWithExtensions);
      this.getVisuallyHiddenTextForFlight(flight);
    }
    this.subscribe(this.searchService.repricingFlight, data => {
      if (!data) {

        for (let flight of this.fares) {
          this.fareRulesObject[flight.uid] = this.checkFareRules(flight as FlightItineraryWithExtensions);
          this.getVisuallyHiddenTextForFlight(flight);
        }
      }
    });
  }

  getAncillaryType(ancillary: string, fare: FlightItinerary): AncillaryStatus {
    switch (ancillary) {
      case 'Hand Baggage':
        const handBaggageAmounts = fare.baggageAllowance
          ?.filter(a => a?.type === 'HandCarry')
          .map(a => a?.qty) as number[];
        const lowestHand = Math.min(...handBaggageAmounts);
        if (lowestHand !== Infinity && lowestHand !== 0) {
          return AncillaryStatus.Included;
        }
        break;
      case 'Checked Baggage':
        const lowestCheck = this.searchParams.getTotalBaggage(fare) || 0;
        if (lowestCheck !== 0 && lowestCheck !== Infinity) {
          return AncillaryStatus.Included;
        }
        break;
      case 'Refund':
        if (this.isAerLingus) {
          return AncillaryStatus.Unknown;
        }
        const zeroFeeCancel = fare.fareConditions?.cancellation?.some(x => x.amount === 0);
        const chargeableCancel = fare.fareConditions?.cancellation?.some(x => x.amount > 0);
        const refunable =
          fare.disclosures?.findIndex(a => a !== null && a === 'Refundable Ticket') !== -1 ||
          fare.additional?.refundable;
        if (zeroFeeCancel || chargeableCancel || refunable) {
          if (zeroFeeCancel || refunable || chargeableCancel) {
            if (chargeableCancel) {
              return AncillaryStatus.Chargeable;
            }

            if (zeroFeeCancel || refunable) {
              return AncillaryStatus.Included;
            }
          }
        }
        break;
      case 'Changes':
        if (this.isAerLingus) {
          return AncillaryStatus.Unknown;
        }
        const zeroFeeChange = fare.fareConditions?.change?.some(x => x.amount === 0);
        const chargeableChange = fare.fareConditions?.change?.some(x => x.amount > 0);
        const changeable =
          fare.disclosures?.findIndex(a => a !== null && a === 'Changeable Ticket') !== -1;
        if (zeroFeeChange || changeable || chargeableChange) {
          if (chargeableChange) {
            return AncillaryStatus.Chargeable;
          }

          if (zeroFeeChange || changeable) {
            return AncillaryStatus.Included;
          }
        }
        break;
      case 'Meal':
        if (fare.disclosures?.findIndex(a => a !== null && a === 'Meal') !== -1) {
          return AncillaryStatus.Included;
        }
        break;
      case 'Seat':
        if (
          fare.disclosures?.findIndex(
            a =>
              a !== null && (a === 'Choice Of Standard Seat' || a === 'Seat Selection At Anytime')
          ) !== -1
        ) {
          return AncillaryStatus.Included;
        }
        break;
      case 'WiFi':
        // Not Implemented
        break;
    }
    return AncillaryStatus.Excluded;
  }

  getFareRules(flight: FlightItinerary): void {
    const { rules, flights } = this.helpers.makeFareRules(
      flight,
      FlightDirectionEnum.Outbound,
      this.isMultiCity
    );
    let inboundRules = null;
    let inboundFlights = null;
    if (this.isReturnFlight) {
      let inboundFareRulesObject = this.helpers.makeFareRules(flight, FlightDirectionEnum.Inbound);
      inboundRules = inboundFareRulesObject.rules;
      inboundFlights = inboundFareRulesObject.flights;
    }
    const flightDetail: FlightItinerary = this.isMultiCity || this.isReturnFlight ? flight : null;
    this.searchParams.openFlightFareRules(
      rules,
      flights,
      flight.sourceId,
      { centered: true, size: 'xl' },
      null,
      flightDetail,
      inboundRules,
      inboundFlights
    );
  }

  selectFare(flight: FlightItineraryWithExtensions) {

    if (this.searchParams.bySchedule) {
      if (!this.selectedItemsId.includes(flight?.scheduleId)) {
        this.selectedMultiCityFlight.emit(flight);
      } else {
        this.deselectMultiCityFlight.emit(flight);
      }
    } else {
      this.addToBasket(flight);
    }
  }

  async addToBasket(flight: FlightItinerary) {
    const isMixedCabin = this.showMixedCabin(flight.outboundFlights) || this.showMixedCabin(flight.inboundFlights);
    if (isMixedCabin) {
      if ((this.isDualFlight && !this.searchParams.selectedFlight.value) ||
        (this.isDualReturn && this.searchParams.selectedFlight.value) ||
        (!this.isDualFlight && !this.isDualReturn)) {
        const mixedCabinResult = await this.dialogService.showMixedCabinDialog();
        if (!mixedCabinResult) return;
      }
    }

    if (this.showAltClass(flight)) {
      const dualClassResult = await this.dialogService.showDifferentClassDialog();
      if (!dualClassResult) return;
    }
    await this.addToBasketEvent.emit(flight);
  }

  checkAviability(id: string): boolean {
    return this.selectedItemsId.includes(id);
  }

  checkFareRules(flight: FlightItineraryWithExtensions): any {
    if (this.isExchange) {
      return {};
    }
    return (
      this.searchService.searches[ServiceType.Flight].getFareRules(flight.journeyHash)
        ?.journeyWhenSelected || {}
    );
  }

  showSpecialFares(flight: FlightItinerary): boolean {
    return this.helpers.getSpecialFare(flight);
  }

  async repriceFlight(flight: FlightItinerary): Promise<void> {
    console.log('+++ Attempting to reprice: ', flight, ' +++');
    this.searchService.gettingFlightClasses.next(true);
    try {
      let availability = await lastValueFrom(this.searchService
        .getAirAvailbility([
          ...(flight.outboundFlights.map(flight => this.helpers.convertFlightJourneyToInputFlightJourney(flight))),
          ...(flight.inboundFlights ? (flight.inboundFlights.map(flight => this.helpers.convertFlightJourneyToInputFlightJourney(flight))) : [])
        ], flight.source));

      this.searchService.gettingFlightClasses.next(false);
      let zeroAv = availability.filter(av => av.availability === 0);
      availability = availability.filter(av => av.availability !== 0);
      availability = availability.sort((a, b) => {
        return a.resBookDesigCode > b.resBookDesigCode ? 1 : -1;
      });
      zeroAv = zeroAv.sort((a, b) => {
        return a.resBookDesigCode > b.resBookDesigCode ? 1 : -1;
      });
      availability = availability.concat(zeroAv);
      const reprice = await this.modalService.open(
        LightningModalTypes.FlightRepriceModalComponent,
        null,
        { flight, availability }
      );

      console.log(reprice);

      // now actually do the reprice call - if we want that
      if (reprice) {
        this.searchService.repricingFlight.next(true);
        const newFlight = await lastValueFrom(
          this.searchService.getFlightAtNewClass(
            this.searchParams.getSearchQuery(),
            flight,
            this.searchParams.adults,
            this.searchParams.children,
            reprice[0].newClass,
            reprice[1] ? reprice[1].newClass : null
          )
        );
        flight.total = newFlight.flights[0].total;
        // flight.outboundFlights = newFlight[0].outboundFlights;
        for (let i = 0; i < newFlight.flights[0].outboundFlights.length; i++) {
          if (flight.outboundFlights[i]) {
            const keys = Object.keys(newFlight.flights[0].outboundFlights[i]);

            keys.forEach(key => {
              if (newFlight.flights[0].outboundFlights[i][key]) {
                flight.outboundFlights[i][key] = newFlight.flights[0].outboundFlights[i][key];
              }
            });
          } else {
            flight.outboundFlights[i] = newFlight.flights[0].outboundFlights[i]; // add any missing flights
          }
        }

        for (let i = 0; i < newFlight.flights[0].inboundFlights.length; i++) {
          if (flight.inboundFlights[i]) {
            const keys = Object.keys(newFlight.flights[0].inboundFlights[i]);

            keys.forEach(key => {
              if (newFlight.flights[0].inboundFlights[i][key]) {
                flight.inboundFlights[i][key] = newFlight.flights[0].inboundFlights[i][key];
              }
            });
          } else {
            flight.inboundFlights[i] = newFlight.flights[0].inboundFlights[i]; // add any missing flights
          }
        }

        flight.taxes = newFlight.flights[0].taxes;
        flight.additional = newFlight.flights[0].additional;
        this.searchService.repricingFlight.next(false);
      }
    } catch (error) {
      this.searchService.gettingFlightClasses.next(false);
      this.searchService.repricingFlight.next(false);
    }
  }

  showSaverAerLingusTravelfusionTooltip(flight: FlightItinerary): boolean {
    return (
      flight.outboundFlights?.some(flight => flight.marketingCarrierName === 'Aer Lingus') &&
      flight.outboundFlights[0]?.additional?.fareBrand?.name === 'Saver' &&
      flight.source === ServiceProvider.Travelfusion
    );
  }

  showAltClass(combinedFlight: FlightItinerary): boolean {
    return (
      combinedFlight.inboundFlights.length &&
      combinedFlight.outboundFlights[0].cabinClass !== combinedFlight.inboundFlights[0].cabinClass
    );
  }

  getRouteHappyInfo(flight: FlightItinerary, items: FlightJourney[]): void {
    const journeys = _.clone(items);
    journeys.forEach((journey) => {
      const unwantedProps = [ //Props that are on FlightJourney but not InputFlightJourney
        "journeyLegs",
        "co2PerItem",
        "co2PerPassenger",
        "journeyHash",
        "safRating",
        "segmentGroup"
      ];
      unwantedProps.forEach((unwantedProp) => {
        delete journey[unwantedProp];
      });
    });

    let dataArgs: DataArgs = {
      itineraries: [
        {
          journeys: journeys as InputFlightJourney[]
        }
      ],
      source: flight.source
    };
    this.searchService
      .getRouteHappy(dataArgs, flight.total.currency)
      .subscribe((data: RouteHappyResults) => {
        this.modalService.open(
          LightningModalTypes.RoutehappyFlightModalComponent,
          { centered: true },
          { results: data }
        );
      });
  }

  getVisuallyHiddenTextForFlight(flight: FlightItinerary): void {
    let flightText = "";
    try {
      let flightOne = flight.outboundFlights[0];
      let flightLast = flight.outboundFlights[flight.outboundFlights.length - 1];
      const price = flight.total.price.toFixed(2);
      const priceWithCurrency = this.currencyPipe.transform(price, flight.total.currency);
      const outTime = moment(flightOne.dateTimeDeparting).format("HH:mm");
      const inTime = moment(flightLast.dateTimeArriving).format("HH:mm");
      const tktClass = flightOne.cabinClass;
      flightText = ` ${priceWithCurrency} ${flightOne.marketingCarrierName} ${tktClass}. ${flightOne.originAirport} ${outTime} to ${flightLast.destinationAirport} ${inTime} fare`;
    } catch {
      flightText = " flight Ticket"; //Just incase it errors this is better than nothing
    }
    this.visuallyHiddenTexts.push(flightText);
  }

  checkIfSpanishResidencyApplies(combinedFlight: FlightItineraryWithExtensions) {
    const outboundSpanish = !!combinedFlight.outboundFlights.find(
      flight => flight.additional.spanishResidencyAirport && flight.additional.spanishResidencyGroup
    );
    const inboundSpanish = !!combinedFlight.inboundFlights.find(
      flight => flight.additional.spanishResidencyAirport && flight.additional.spanishResidencyGroup
    );
    return outboundSpanish || inboundSpanish;
  }

  showMixedCabin(flights: FlightJourney[]) {
    return this.searchParams.showMixedCabin(flights);
  }
}

export const ancillariesArray = [
  { title: 'Hand Baggage', icon: 'suitcase', key: 'handBaggage' },
  { title: 'Checked Baggage', icon: 'suitcase-rolling', key: 'checkedBaggage' },
  { title: 'Refund', icon: 'coins', key: 'refundAndChanges' },
  { title: 'Changes', icon: 'calendar-alt', key: 'refundAndChanges' }
];

