<ng-template #iconWarn>
  <i class="ico-cancel light-color-warning"></i>
</ng-template>
<ng-template #noFeatureInfo>
  <div class="pnl-feature">
    <i class="ico-exclaim-1 light-color-info"></i>
    <span i18n="@@pnlInformationUnavailable">Information unavailable</span>
  </div>
</ng-template>
<ng-container
  *ngIf="{
    results: (searchParams.results | async) || [],
    isvalid: searchParams.isValid | async,
    messages: (searchParams.messages | async) || []
  } as data">
  <h2 class="search_content_titles" i18n="@@fastTrack_searchCriteria">Search Criteria</h2>

  <div class="background-30">
    <app-validate-errors [messages]="data.messages"></app-validate-errors>
    <a href="#parking" (click)="skipToResultContent()" class="sr-only sr-only-focusable"
      >Skip to Result Content</a
    >
    <app-invoked-user></app-invoked-user>
    <app-search-toggle>
      <app-fast-track-search></app-fast-track-search>
      <div class="flex-row-container flex-row-container--right">
        <button
          type="button"
          (click)="performSearch()"
          [disabled]="!data.isvalid"
          class="large-button large-button--search"
          type="button"
          i18n="@@fastTrackSearch">
          Search
        </button>
      </div>
    </app-search-toggle>
    <app-risk-alerts
      *ngIf="!searchService.search_loading"
      [countryCode]="searchParams.locationCode?.countryCode"></app-risk-alerts>
  </div>

  <app-fast-track-loading-screen [animated]="false"></app-fast-track-loading-screen>
  <button
    class="large-button"
    *ngIf="flightSearchParams.fasttrackFromFlightSearchPerformed"
    routerLink="/results/flights"
    id="fasttrack-button-back-to-flights">
    Back to Flights
  </button>
  <div id="parking" class="pnl-results-container" *ngIf="!mapView">
    <app-no-results
      *ngIf="data.results && data.results.length === 0"
      [type]="searchType"></app-no-results>
    <div class="pnl-item" *ngFor="let item of data?.results | loungesPriceSort; let i = index">
      <div class="pnl-image" *ngIf="item.logo || item.images.length > 0">
        <img
          [src]="item.logo ? removeSpaces(item.logo) : removeSpaces(item.images[0])"
          alt="{{ item.name }} logo" />
      </div>
      <div class="pnl-desc">
        <h3>{{ item?.name }} <span i18n="@@fastTrackName"> - Fast Track</span></h3>
      </div>
      <app-preferred-icons
        [tmcPreferred]="item.tmcPreferred"
        [tmcPreferredLogoUrl]="item.tmcPreferredLogoUrl"
        [tmcPreferredMessage]="item.tmcPreferredMessage"
        [clientPreferred]="item.officePreferred || item.companyPreferred"
        [companyPreferredMessage]="item.companyPreferredMessage"
        [officePreferredMessage]="item.officePreferredMessage">
      </app-preferred-icons>
      <div class="pnl-actions">
        <button
          [disabled]="item.unavailable && !canOverride"
          (click)="addToCart(item)"
          class="light-btn light-btn-fancy"
          id="fast-track-button-add-to-basket-{{ i }}"
          attr.aria-label="{{ item.price | currency : item.currency || 'GBP' }} add to cart {{
            item.name
          }}">
          <span class="price-wrapper">
            {{ item.price | currency : item.currency || 'GBP' }}
          </span>
          <span i18n="@@pnlAddToCart" *ngIf="!displayItinerary">Add to basket</span>
          <span i18n="@@pnlAddToItinerary" *ngIf="displayItinerary">Add to itinerary</span>
        </button>
        <app-warning-tags [iconId]="i" [ticket]="item"></app-warning-tags>
      </div>
      <div class="pnl-details">
        <div
          (click)="toggleItemDetails(item)"
          (keydown.enter)="toggleItemDetails(item)"
          attr.aria-expanded="{{ item.showDetails ? 'true' : 'false' }}"
          aria-controls="itemexpanded"
          aria-live="polite"
          role="button"
          tabindex="0"
          attr.aria-label="{{ item.showDetails ? 'Hide' : 'Show' }} additional information for {{
            item.provider
          }}"
          aria-live="polite">
          <span *ngIf="item.showDetails" i18n="@@pnlHideAdditionalInformation"
            >Hide additional information</span
          >
          <span *ngIf="!item.showDetails" i18n="@@pnlShowAdditionalInformation"
            >Show additional information</span
          >
        </div>
        <div
          class="show_photos_text"
          (click)="toggleItemPhotos(item.images)"
          (keydown.enter)="toggleItemPhotos(item.images)"
          role="button"
          tabindex="0"
          attr.aria-label="Show photos for {{ item.name }}"
          aria-live="polite">
          <span i18n="@@pnlShowPhotos">Show Photos</span>
        </div>
      </div>
      <app-fast-track-details *ngIf="item.showDetails" [fastTrack]="item"></app-fast-track-details>
    </div>
  </div>
  <app-map-view
    [item]="selectedItem"
    *ngIf="mapView"
    (closeMapView)="onCloseMapView()"></app-map-view>
</ng-container>
