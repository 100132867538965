<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@modalCustomErrorOccurredTitle"
        id="modal-header">{{ errorTitle }}</h3>
    <button type="button"
            class="close"
            attr.aria-label="{{errorTitle}} Close"
            ngbAutoFocus
            i18n-aria-label
            (click)="OK()">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p i18n="@@modalCustomErrorMessage">{{ errorMessage }}</p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="modal_button_okay"
            (click)="OK()"
            i18n="@@modalCustomErrorOK">
            Ok
    </button>
  </div>
</div>